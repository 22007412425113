// 绑定手机
export const bindPhone 					= (params) => window._http('/User-bindPhone', params)

// 绑定微信
export const bindWechat 				= (params) => window._http('/User-bindWx', params)

// 获取头像
export const getBase64Avatar 		= (params) => window._http('/User-getBase64Avatar', params)

// 修改资料
export const userModify 				= (params) => window._http('/User-modify', params)

// 查询用户资料
export const getUserInfo 				= (params) => window._http('/User-info', params)

// 查询用户红包数量
export const getUserRedpackages = (params) => window._http('/User-getRedpackages', params)

//获取用户会员等级
export const getUsergrade 			= (params) => window._http('/User-levelInfo', params)

// 绑定手机
export const wxBindPhone 					= (params) => window._http('/Auth-wxBindPhone', params)