<template>
  <div class="page">
    <div class="page_body" v-if="!in_wechat">
      <div class="input_zone mt100">
        <van-cell-group>
          <van-field
            v-validate="'required|numeric|length:11|max:11|mobile'"
            v-model="celPhone"
            name="celPhone"
            type="text"
            :error-message="errors.first('celPhone')"
            placeholder="请输入手机号"
          />
        </van-cell-group>

        <van-cell-group class="mt10">
          <van-field
            v-validate="'required|min:6|max:16'"
            v-model="password"
            name="password"
            type="password"
            :error-message="errors.first('password')"
            placeholder="请输入密码"
          />
        </van-cell-group>

        <p class="mt10 tr">
          <router-link class="findpwd" to="/findpwd">找回密码</router-link>
        </p>
      </div>

      <div class="mt50 tc">
        <div class="account_login_btn">
          <PinkBtn text="确认登录" @click="validate"/>
        </div>
        <div class="wechat_login_btn mt20">
          <img @click="wxloginForWeb" width="100%" :src="img.wechat_login_btn" alt="微信登录">
        </div>
      </div>

      <p class="info_zone mt15 tc">
        <router-link to="/reg">没有淘街坊帐号？点我注册</router-link>
      </p>
    </div>

    <div class="copyright tc">&copy; 淘街坊 - 发现身边优惠</div>
  </div>
</template>
 
<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import PinkBtn from "@/components/PinkBtn";
import { login, wechatLogin } from "@/api/auth";
import { getUserInfo } from "@/api/user";
import ls from "@/libs/localstore";
import { Field, CellGroup } from "vant";
import { isMicroMessenger } from "@/utils/user-agent";
import {checkInviteCode} from '@/api/invite'
// import CryptoJS from "crypto-js"

Vue.use(Field).use(CellGroup);

require("@/libs/validate.js");

export default {
  name: "Login",
  components: {
    StatusBar,
    NavBar,
    PinkBtn
  },
  data() {
    return {
      img: {
        close_btn: require("@/assets/common/close_btn.png"),
        wechat_login_btn: require("@/assets/login/wechat_login_btn.png")
      },
      celPhone: null,
      password: "",
      is_wechat_installed: false,
      in_wechat: false
    };
  },
  created: function() {
    this._checkCode();
  },
  mounted() {
		document.addEventListener('WeixinJSBridgeReady', function () {
				parent.WeixinJSBridge.call('hideOptionMenu')
		}, false)
		parent.WeixinJSBridge.call('hideOptionMenu')
    if (isMicroMessenger) {
      this.in_wechat = true;
    }
  },
  methods: {
		_checkCode() {
			let token = ls.getToken()
			if(!!token) {
				this._checkIsLogin()
				return false
			}

			let code = this.$route.query.code;
			if(!!code) {
				this.realWechatLogin(code)
				return false
			}

			this._checkIsLogin()
		},
    _checkIsLogin() {
      let params = {
         
      };
      getUserInfo(params).then(res => {
        if (res.errCode == 0) {
          this.$router.go(-2);
        } else {
          // if (isMicroMessenger) {
            this.wxloginForWeb();
          // }else{
					// 	this.login();
					// }
        }
      }).catch(err => {
				this.wxloginForWeb();
			});
    },
    validate: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.login();
        } else {
          this.$toast("请按提示正确填写所有信息");
        }
      });
    },
    login: function() {
      let params = {
        phone: this.celPhone,
        loginType: "password",
        password: this.password
      };

      login(params)
        .then(res => {
          if (res.errCode == 0) {
            this.$toast.success("登录成功");
            // 登录成功后的业务
            this.loginSuccessCallback(res.data.token, res.data.userInfo);
          } else {
            this.$toast(res.errMsg);
          }
        })
        .catch(err => {
          this.$toast(err.errMsg);
          // 做些错误处理
        });
    },
    loginSuccessCallback: function(token, userInfo) {
      this.$cookie.set("user-token", token, { expires: "7d" });
      // 更新token
      ls.setToken(token);
      // 更新个人信息
      ls.setUserInfo(userInfo);
      // 获取登录前的页面
			let url = this.$cookie.get("beforeLoginUrl");
			// 绑定邀请码
			this._checkInviteCode(token)
      // 若是url不存在或者是授权页面则跳到首页
      if (!url || url.indexOf("/login") != -1) {
        this.$router.push("/");
      } else {
        // 清空这个cookie
        this.$cookie.set("beforeLoginUrl", "");
        // 跳转至开始的目标页面
        this.$router.push(url);
      }
		},
		// 绑定邀请码
		_checkInviteCode(token){
			let invite_code = localStorage.getItem('invite-code');
			if(!invite_code) {
				return false;
			}
			let params = {
        token: token,
				code: invite_code
      };

      checkInviteCode(params).then(res => {});
		},

    // 网页版微信登录
    wxloginForWeb() {
			// if(!this.in_wechat) {
			// 	this.$toast('请在微信浏览器打开')
			// 	return false
			// }

			let toPath = '/login?hash='+Math.random().toString().replace('0.','')
			const APPID = 'wx6d025d1cd025569d'
			const REDIRECT_URI = encodeURIComponent(window.location.protocol+'//'+window.location.host+toPath)
			const SCOPE = 'snsapi_userinfo'
			window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`
    },
    realWechatLogin: function(code) {
      let params = {
				code: code,
				appid: 'wx6d025d1cd025569d'
      };

      wechatLogin(params)
        .then(res => {
          if (res.errCode == 0) {
            this.$toast.success("登录成功");
            // 登录成功后的业务
            this.loginSuccessCallback(res.data.token, res.data.userInfo);
          } else if(res.errCode == 'ERR_02222') {
						// 跳转绑定手机号
            this.$toast(res.errMsg);
						this.$router.push({
							path: '/bindPhone',
							query: {wx_id: res.data.wx_id}
						})
          } else if(res.errCode == 'ERR_02233') {
						// 更新token
						ls.setTmpToken(res.data.token);
						// 更新个人信息
						ls.setUserInfo(res.data.userInfo);

						this.$router.push({
							path: '/invite-input'
						})
					}else{
						this.$toast(res.errMsg);
					}
        })
        .catch(err => {
          this.$toast(err.errMsg);
          // 做些错误处理
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  background: #fff;
}
.input_zone {
  padding: 0 30px;
}
.findpwd {
  color: #4a4a4a;
}
.info_zone {
  font-size: 16px;
  color: #4a4a4a;
}
.info_zone a {
  color: #4a4a4a;
}
.van-cell {
  font-size: 16px;
}
[class*="van-hairline"]::after {
  border-top-color: transparent !important;
}
.account_login_btn {
  padding: 0 20px;
}
.wechat_login_btn {
  padding: 0 10px;
}
</style>
